import {createRouter, createWebHashHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Portfolio from '../views/Portfolio.vue'
import contact from "@/views/Contact";

function lazyLoad(view) {
    return () => import(`@/views/${view}.vue`)
}

const routes = [
    {
        path: '/',
        name: 'home',
        props: true,
        component: HomeView,
        meta: {
            title: "Mammoet | Hekwerk Oldebroek"
        }
    },
    {
        path: '/projecten',
        name: 'projecten',
        props: true,
        component: lazyLoad('Portfolio'),
        meta: {
            title: "Projecten | Mammoet Hekwerk"
        }
    },
    {
        path: '/producten',
        name: 'producten',
        props: true,
        component: lazyLoad('Producten'),
        meta: {
            title: "Producten | Mammoet Hekwerk"
        }
    },
    {
        path: '/contact',
        name: 'Contact',
        props: true,
        component: contact,
        meta: {
            title: "Contact | Mammoet Hekwerk"
        }
    },
    {
        path: '/hekwerk',
        name: 'hekwerk',
        props: true,
        component: lazyLoad('Hekwerk'),
        meta: {
            title: "Hekwerk | Mammoet Hekwerk"
        }
    },
    {
        path: '/poorten',
        name: 'poorten',
        props: true,
        component: lazyLoad('Poorten'),
        meta: {
            title: "Poorten | Mammoet Hekwerk"
        }
    },
    {
        path: '/beveiliging',
        name: 'beveiliging',
        props: true,
        component: lazyLoad('Beveiliging'),
        meta: {
            title: "Beveiliging | Mammoet Hekwerk"
        }
    },
    {
        path: '/overig',
        name: 'overig',
        props: true,
        component: lazyLoad('Overig'),
        meta: {
            title: "Overig | Mammoet Hekwerk"
        }
    },
    // all products
    {
        path: '/gaashekwerk',
        name: 'gaashekwerk',
        component: lazyLoad('Gaashekwerk'),
        meta: {
            title: "Gaashekwerk | Mammoet Hekwerk"
        }
    },
    {
        path: '/looppoort',
        name: 'looppoort',
        component: lazyLoad('Looppoort'),
        meta: {
            title: "Looppoort | Mammoet Hekwerk"
        }
    },
    {
        path: '/dubbele-looppoort',
        name: 'dubbele looppoort',
        component: lazyLoad('Dubbele-looppoort'),
        meta: {
            title: "Dubbele looppoort | Mammoet Hekwerk"
        }
    },
    {
        path: '/dubbel-staafmat',
        name: 'dubbel staafmat',
        component: lazyLoad('Dubbel-staafmat'),
        meta: {
            title: "Dubbel staafmat | Mammoet Hekwerk"
        }
    },
    {
        path: '/spijlhekwerk',
        name: 'spijlhekwerk',
        component: lazyLoad('Spijlhekwerk'),
        meta: {
            title: "Spijlhekwerk | Mammoet Hekwerk"
        }
    },
    {
        path: '/schuifpoort',
        name: 'schuifpoort',
        component: lazyLoad('Schuifpoort'),
        meta: {
            title: "Schuifpoort | Mammoet Hekwerk"
        }
    },
    {
        path: '/automatisering',
        name: 'automatisering',
        component: lazyLoad('Automatisering'),
        meta: {
            title: "Automatisering | Mammoet Hekwerk"
        }
    },
    {
        path: '/camerabeveiliging',
        name: 'camerabeveiliging',
        component: lazyLoad('Camerabeveiliging'),
        meta: {
            title: "Camerabeveiliging | Mammoet Hekwerk"
        }
    },
    {
        path: '/doorrijbeveiliging',
        name: 'doorrijbeveiliging',
        component: lazyLoad('Doorrijbeveiliging'),
        meta: {
            title: "Doorrijbeveiliging | Mammoet Hekwerk"
        }
    },
    {
        path: '/sportveldafrastering',
        name: 'sportveldafrastering',
        component: lazyLoad('Sportveldafrastering'),
        meta: {
            title: "Sportveldafrastering | Mammoet Hekwerk"
        }
    },
    {
        path: '/hekwerk-detectie',
        name: 'hekwerk-detectie',
        component: lazyLoad('Hekwerk-detectie'),
        meta: {
            title: "Hekwerk detectie | Mammoet Hekwerk"
        }
    },
    {
        path: '/slagbomen',
        name: 'slagbomen',
        component: lazyLoad('Slagbomen'),
        meta: {
            title: "Slagbomen | Mammoet Hekwerk"
        }
    },
    {
        path: '/automatisering',
        name: 'automatisering',
        component: lazyLoad('Automatisering'),
        meta: {
            title: "Automatisering | Mammoet Hekwerk"
        }
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
        return {top: 0};
    }
});


router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Default Title'; // Set the document title
    const pageTitle = to.name || 'default'; // Use the route name as the class, or a default class
    document.body.className = `page-${pageTitle.toLowerCase().replace(/\s+/g, '-')}`; // Set the body class
    next();
});

export default router
