<template>
  <div class="portfolio">
    <sub-banner>
      <template v-slot:text>
        <h1>Foto's</h1>
      </template>
    </sub-banner>
    <div class="portfolio-grid container" style="margin-top: -50px">
      <div class="portfolio-item backdrop backdrop-xl" style="min-height: 550px;max-height: 550px;">
        <img src="/images/projecten/mammoet-hekwerk1.webp" alt="bas van loo project">
      </div>
      <div class="portfolio-item backdrop backdrop-xl" style="min-height: 550px;max-height: 550px;">
        <img src="/images/projecten/camera1.jpeg" alt="bas van loo project">
      </div>
      <div class="portfolio-item backdrop backdrop-xl" style="min-height: 550px;max-height: 550px;">
        <img src="/images/projecten/mammoet-hekwerk2.jpg" alt="bas van loo project">
      </div>
      <div class="portfolio-item backdrop backdrop-xl" style="min-height: 550px;max-height: 550px;">
        <img src="/images/projecten/camera2.jpeg" alt="bas van loo project">
      </div>
      <div class="portfolio-item backdrop backdrop-xl" style="min-height: 550px;max-height: 550px;">
        <img src="/images/projecten/hekwerk1.jpeg" alt="bas van loo project">
      </div>
      <div class="portfolio-item backdrop backdrop-xl" style="min-height: 550px;max-height: 550px;">
        <img src="/images/projecten/hekwerk2.jpeg" alt="bas van loo project">
      </div>
      <div class="portfolio-item backdrop backdrop-xl" style="min-height: 550px;max-height: 550px;">
        <img src="/images/projecten/hekwerk3.jpeg" alt="bas van loo project">
      </div>
      <div class="portfolio-item backdrop backdrop-xl" style="min-height: 550px;max-height: 550px;">
        <img src="/images/projecten/mammoet-hek.jpeg" alt="bas van loo project">
      </div>
      <div class="portfolio-item backdrop backdrop-xl" style="min-height: 550px;max-height: 550px;">
        <img src="/images/projecten/mammoet-hek2.jpeg" alt="bas van loo project">
      </div>
      <div class="portfolio-item backdrop backdrop-xl" style="min-height: 550px;max-height: 550px;">
        <img src="/images/projecten/elektrische-schuifpoort.jpeg" alt="bas van loo project">
      </div>
      <div class="portfolio-item backdrop backdrop-xl" style="min-height: 550px;max-height: 550px;">
        <img src="/images/projecten/mammoet-hek3.jpeg" alt="bas van loo project">
      </div>

    </div>
  </div>
</template>

<script>
import SubBanner from "@/components/SubBanner";

export default {
  name: "Portfolio",
  components: {
    SubBanner,
  },
  created() {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss" scoped>

$gold: #D6AD60;
$gold-d2: #9a7836;
$coal: #122620;

.portfolio {

  .portfolio-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
    }
    gap: 45px;
    height: fit-content;
    padding-bottom: 100px;

    .portfolio-item {
      grid-column: span 2;
      display: flex;
      width: 100%;
      background: $gold;
      height: 100px;
      position: relative;
      max-height: 500px;
      min-height: 500px;
      @media (max-width: 900px) {
        min-height: 550px;
        max-height: 550px;
      }

      img {
        width: 100%;
        height: 100% !important;
        object-fit: cover;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(47.13deg, rgba(30, 30, 30, 0.55) 0%, rgba(207, 215, 255, 0) 100%);
        z-index: 1;
      }
    }

    .portfolio-item:nth-child(-n+2), .portfolio-item:nth-child(8n+10), .portfolio-item:nth-child(8n+9) {
      grid-column: span 3;
      max-height: 500px;
      min-height: 500px;
      @media (max-width: 900px) {
        min-height: 550px;
        max-height: 550px;
      }
    }
  }
}
</style>
